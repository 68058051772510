<template>
  <div>
    <v-container >
      <v-row>
        <!-- Columna para el Formulario -->
        <v-col cols="12">
          <v-row elevation="6" style="background-color: #fffdfd;  position: relative">
            <!-- Campo de búsqueda de barcos -->
            <v-col cols="12" sm="3" md="3">
              <v-menu
                v-model="suggestionsMenu"
                transition="scale-transition"
                offset-y
                max-width="300"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="searchBoat"
                    append-icon="mdi-magnify"
                    label="Buscar Barco"
                    solo
                    flat
                    hide-details
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @input="updateSuggestions"
                    @keydown.enter="finalizeSearch"
                    @change="resetSearchIfEmpty"
                  ></v-text-field>
                </template>

                <v-list>
                  <!-- Sugerencias de búsqueda -->
                  <v-list-item
                    v-for="(suggestion, index) in suggestions" :key="index.id"
                    @click="selectSuggestion(suggestion)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ suggestion.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ suggestion.mmsi }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider></v-divider>

                  <!-- Historial de búsqueda -->
                  <v-subheader>Historial de búsqueda</v-subheader>
                  <v-list-item
                    v-for="(history, index) in searchHistory"
                    :key="history.id || index.id"
                  >
                    <v-list-item-content @click="applyHistory(history)">
                      <v-list-item-title>{{ history }}</v-list-item-title>
                    </v-list-item-content>
                    <v-btn icon @click="removeHistory(index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item>

                  <v-divider></v-divider>
                  <v-btn text block color="red" @click="clearHistory">
                    Limpiar historial
                  </v-btn>
                </v-list>
              </v-menu>
            </v-col>

            <!-- Selector de tipo de eventos -->
            <v-col cols="12" sm="3" md="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
                      <v-btn
                        icon
                        v-bind="{ ...attrs, ...menuAttrs }"
                        v-on="{ ...on, ...menuOn }"
                      >
                        <v-icon>mdi-filter-variant</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-text>
                        <v-checkbox
                          v-for="item in itemsTipoEvento"
                          :key="item.value"
                          v-model="tipoEvento"
                          :value="item.value"
                          @change="searchBoats"
                        >
                          <template v-slot:label>
                            <v-avatar size="24" class="mr-2">
                              <img :src="getIconUrl(item.value)" alt="icon" />
                            </v-avatar>
                            {{ item.text }}
                          </template>
                        </v-checkbox>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
                <span>Filtrar por tipo de eventos</span>
              </v-tooltip>
            </v-col>



            <!-- Botón que mostrará el filtro por fecha -->
            <v-col cols="12" md="3" style="justify-content: flex-end; position: relative; right: 30px">
              <v-menu
                v-model="menu"
                offset-y
                :close-on-content-click="opcionesFiltro[selectedFilter] !== 'Rango Personalizado'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="blue darken-3"
                    dark
                    block
                  >
                    <v-icon left>mdi-calendar</v-icon>
                    {{ tituloBoton }}
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item-group
                    v-model="selectedFilter"
                    @change="onFiltroSeleccionado"
                  >
                    <!-- Opciones de filtro -->
                    <v-list-item
                      v-for="(opcion, index) in opcionesFiltro"
                      :key="index.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ opcion }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>

                  <!-- Mostrar el selector de rango personalizado -->
                  <v-list-item v-if="opcionesFiltro[selectedFilter] === 'Rango Personalizado'">
                    <v-list-item-content>
                      <v-date-picker
                        v-model="customDateRange"
                        range
                        :max="new Date().toISOString().split('T')[0]"
                        @input="aplicarFiltroRangoPersonalizado"
                      ></v-date-picker>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>

            <!-- Botón de Registrar Área -->
            <v-col cols="12" md="1" style="justify-content: flex-end; position: relative; right: 30px">
              <v-btn color="green darken-3" dark block @click="abrirModal('registrarArea')">
                <v-icon left>mdi-plus</v-icon>
                Registrar Área
              </v-btn>
            </v-col>

            <v-col cols="12" md="2" style="justify-content: flex-end; position: relative; right: -80px">
              <v-btn color="yellow-darken-1" dark block @click="abrirModal('consultaZona')">
                <v-icon icon="fa:fas fa-search"></v-icon>
                Consulta por Zona de Área
              </v-btn>
            </v-col>

            <v-dialog v-model="showModal" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  {{ modalTitle }}
                </v-card-title>
                <v-card-text>
                  <v-form ref="form">
                    <!-- Formulario para registrar área -->
                    <template v-if="modalType === 'registrarArea'">

                        <v-card-text>
                          <v-form ref="form">
                            <v-text-field
                              label="Nombre del Área"
                              v-model="nuevaArea.name"
                              :rules="[rules.required]"
                              outlined
                            ></v-text-field>
                            <v-text-field
                              label="ID del Área (AOI)"
                              v-model="nuevaArea.aoi_id"
                              :rules="[rules.required]"
                              outlined
                            ></v-text-field>
                          </v-form>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="cerrarModal">Cancelar</v-btn>
                          <v-btn color="green darken-3" text @click="registrarArea">Registrar</v-btn>
                        </v-card-actions>

                    </template>

                    <!-- Formulario para consulta por zona -->
                    <!-- Formulario para consulta por zona -->
                    <template v-if="modalType === 'consultaZona'">
                      <v-select
                        v-model="zonaSeleccionada"
                        :items="areas"
                        item-text="name"
                        item-value="aoi_id"
                        label="Seleccionar Zona"
                        outlined
                        :rules="[rules.required]"
                      ></v-select>

                      <v-text-field
                        v-model="fechaInicio"
                        label="Fecha de Inicio"
                        type="date"
                        :rules="[rules.required]"
                        outlined
                      ></v-text-field>

                      <v-text-field
                        v-model="fechaFin"
                        label="Fecha de Fin"
                        type="date"
                        :rules="[rules.required]"
                        outlined
                      ></v-text-field>

                      <v-btn color="blue darken-3" block @click="consultarZona">
                        Consultar
                      </v-btn>
                    </template>

                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="cerrarModal">Cerrar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>




          </v-row>
        </v-col>

        <!-- Columna para el Mapa -->
        <v-col cols="12">
          <v-card class="map-card" elevation="0">
            <v-card-title class="text-h6 green--text text--darken-3">
              Mapa de Seguimiento
              <button
                @click="clearSelection"
                style="padding: 5px; background:#1AAB8A; color:#fff; border-radius: 10px; cursor:pointer; outline:none; position: relative; top: 0; right: -10px"
              >
                limpiar
              </button>

              <!-- Lista horizontal de tipos de eventos -->
              <v-row class="mt-3" justify="space-around" align="center" no-gutters>
                <v-col
                  v-for="item in itemsTipoEvento"
                  :key="item.value"
                  class="d-flex align-center"
                  cols="auto"
                >
                  <v-avatar size="36" class="mr-2">
                    <img :src="getIconUrl(item.value)" alt="icon" />
                  </v-avatar>
                  <span style="font-size: 0.85rem;">{{ item.text }}</span>
                </v-col>
              </v-row>
            </v-card-title>

            <v-card-text>
              <div>
                <link
                  rel="stylesheet"
                  href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
                />
                <l-map
                  ref="mapLeaflet"
                  style="height: 700px; border-radius: 8px; width: 100%; z-index: 1"
                  :zoom="zoom"
                  :center="center"
                >
                  <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                  <l-marker
                    v-for="vessel in vessels"
                    :key="vessel.mmsi"
                    :lat-lng="vessel.point.lat != null && vessel.point.lon != null
                     ? [vessel.point.lat, vessel.point.lon]
                     : [0, 0]"
                    @click="mostrarEventos(vessel)"
                    :ref="'marker-' + vessel.mmsi"
                  >
                    <l-icon
                      :icon-url="getIconUrl(vessel.eventType)"
                      :icon-size="[40, 40]"
                      :icon-anchor="[20, 20]"
                    ></l-icon>
                    <l-popup>
                      <div>
                        <h3>{{ vessel.name }}</h3>
                        <div v-if="vessel">
                          <v-icon left>
                            {{ getIconName( vessel.eventType) }}
                          </v-icon>
                          <p>
                            <strong>Tipo de evento:</strong>
                            {{ traducirTipoEvento(vessel.eventType) }}
                          </p>
                        </div>
                        <div v-else>
                          <p>No hay eventos disponibles para este buque.</p>
                        </div>
                      </div>
                    </l-popup>
                  </l-marker>

                </l-map>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>

      <v-container>
        <v-card class="elevation-2">
          <!-- Encabezado de la tabla -->
          <v-toolbar flat color="" dark>
            <v-toolbar-title>Eventos por Embarcación</v-toolbar-title>
          </v-toolbar>

          <!-- Tabla de datos -->
          <v-data-table
            :headers="headers"
            :items="tableData"
            :items-per-page="5"
            class="elevation-1"
            dense
            fixed-header
            height="400px"
          >
            <!-- Cuerpo de la tabla -->
            <template v-slot:body="{ items }">
              <tr
                v-for="(item, index) in items"
                :key="index.id"
                class="hoverable-row"
              >
                <!-- Columna de nombre -->
                <td class="text-start font-weight-bold">
                  {{ item.boatName || 'Sin nombre' }}
                </td>
                <!-- Columna de tipo de evento -->
                <td class="text-uppercase">
                  {{ traducirTipoEvento(item.eventType) }}
                </td>
                <!-- Columna de fecha -->
                <td>
                  {{ formatDate(item?.timestamp) }}
                </td>
                <!-- Columna de acción -->
                <td>
                  <v-btn
                    small
                    outlined
                    color="primary"
                    class="text-capitalize"
                    @click="redirectToVessel(item.vesselId)"
                  >
                    Ver más
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-container>

      <v-alert
        v-if="showAlert && message !== null"
        class="custom-alert mt-3"
        border="left"
        elevation="2"
        shaped
      >
        <v-icon class="mr-3" color="white" size="24">mdi-information</v-icon>
        {{ message }}
      </v-alert>

      <div v-if="Loading" class="spinner-overlay">
        <div class="spinner-box">
          <div class="spinner"></div>
        </div>
      </div>

    </v-container>
  </div>

</template>

<script>
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from "vue2-leaflet";
import axios from 'axios';
import {
  getRendezvousInline,
  skylightEvents,
  vesselTrackingAndHistory,
  areaOfInterest,
  allAreaOfInterest, eventsQueryByArea
} from "./../config.js";
import L from 'leaflet';
import 'leaflet-polylinedecorator';
import Swal from "sweetalert2";



export default {
  props: ["cod_tracing"],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
  },
  data() {
    return {
      vessels: [],
      decorators: [],
      allVessels: [],
      isVesselsReady: false,
      Loading: false,
      dialog: false,
      message: null,
      codigoNumerico: "",
      tipoEvento: [],
      itemsTipoEvento: [
        { text: "Encuentro Estándar", value: "standard_rendezvous", icon: "mdi-account-group" },
        { text: "Encuentro Oscuro", value: "dark_rendezvous", icon: "mdi-weather-night" },
        { text: "Pesca", value: "viirs", icon: "mdi-fish" },
        { text: "Historial de actividad pesquera", value: "fishing_activity_history", icon: "mdi-chart-timeline" },
        { text: "Rango de Velocidad", value: "speed_range", icon: "mdi-speedometer" },
        { text: "Detecciones de embarcaciones", value: "detection", icon: "mdi-radar" },
      ],
      searchBoat: '',
      allBoats: [],
      showAlert: false,
      selectedEvent: null,
      eventosSeleccionados: [],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 13,
      center: [12.548883, -81.718130],
      map: null,
      routeLayer: null,
      routes: [],
      startMarkers: [],
      endMarkers: [],
      showEventsButton: false,
      selectedVessel: null,
      drawerVisible: false,
      drawer: false,
      imag: require('@/assets/iconic.png'),
      dark: require('@/assets/dark.png'),
      history: require('@/assets/history.png'),
      detection: require('@/assets/detection.png'),
      standard: require('@/assets/estandar.png'),
      speed: require('@/assets/speed_range.png'),
      defaul: require('@/assets/deafaul.png'),
      menu: false,
      selectedFilter: null,
      opcionesFiltro: [
        "Últimas 6 Horas",
        "Últimas 12 Horas",
        "Últimas 24 Horas",
        "Últimas 48 Horas",
        "Últimas 72 Horas",
        "Última Semana",
        "Últimas 2 Semanas",
        "Último Mes",
        "Rango Personalizado",
      ],
      customDateRange: null,
      tituloBoton: "Últimos Eventos",
      headers: [
        { text: "Embarcación", value: "boatName" },
        { text: "Tipo de Evento", value: "eventType" },
        { text: "Fecha y Hora", value: "timestamp" },
        { text: "Acción", value: "action", sortable: false },
      ],
      tableData: [],
      showTable: false,
      selectedBoatName: "Sin Nombre",
      vesselId: "Id No Encontrado",
      suggestions: [],
      suggestionsMenu: false,
      searchHistory: [],
      endDate: null,
      startDate: null,
      menuStart: false,
      menuEnd: false,
      showModal: false,
      nuevaArea: {
        aoi_id: '',
        name: ''
      },
      rules: {
        required: value => !!value || 'Este campo es obligatorio',
      },
      areas:[],
      modalType: '',
      modalTitle: '',
      zonaConsulta: '',
      zonaSeleccionada: '',
    };
  },
  created() {
   /* this.obtenerTodosLosEventos().then(() => {
      this.centrarMapaEnBarco();
    });*/

    const now = new Date();
    const twoDaysAgo = new Date(now);
    twoDaysAgo.setDate(now.getDate() - 2);

    const startTime = twoDaysAgo.toISOString().split("T")[0] + "T00:00:00";
    const endTime = now.toISOString().split("T")[0] + "T23:59:59";


    this.obtenerRendezvous(startTime, endTime);


    const codTracing = this.$route.params.cod_tracing;
    if (codTracing) {
      this.codigoNumerico = codTracing;
    }

    this.getAllArea();

  },
  computed:{

  },
  methods: {
    abrirModal(tipo) {
      this.modalType = tipo;

      this.modalTitle = tipo === 'registrarArea'
        ? 'Registrar Área'
        : 'Consulta por Zona de Área';

      this.showModal = true;
    },
    cerrarModal() {
      this.showModal = false;


      if (this.modalType === 'registrarArea') {
        this.nuevaArea = { aoi_id: '', name: '' };
      } else if (this.modalType === 'consultaZona') {
        this.zonaConsulta = '';
      }

      this.modalType = '';
    },

    async registrarArea() {

      this.loading = true;

      const valido = await this.$refs.form.validate();
      if (!valido) {
        this.loading = false;
        return;
      }

      try {
        await axios.post(areaOfInterest, this.nuevaArea);

        Swal.fire({
          title: 'Éxito',
          text: 'El área ha sido registrada correctamente.',
          icon: 'success',
          confirmButtonText: 'Aceptar',
        });

        this.cerrarModal();
      } catch {
        // Manejar el error con SweetAlert
        Swal.fire({
          title: 'Error',
          text: 'Hubo un problema al registrar el área. Inténtalo nuevamente.',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
      } finally {
        this.loading = false;
      }
    },

    async getAllArea() {
      try {
        const response = await axios.get(allAreaOfInterest);
        this.areas = response.data.data;
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Ocurrió un problema al obtener las áreas.',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
      }
    },

    onFiltroSeleccionado() {
      if (this.opcionesFiltro[this.selectedFilter] !== "Rango Personalizado") {
        this.aplicarFiltro();
        this.menu = false;
      }else {
        this.menu = true;
      }
    },

    centrarMapaEnBarco() {
      const boatName = this.$route.query.boat_name;
      if (boatName) {
        const vessel = this.vessels.find((v) => v.boat_name === boatName);
        if (vessel) {
          this.center = [vessel.point.lat, vessel.point.lon];
          this.zoom = 14;

          this.$nextTick(() => {
            const markerRef = this.$refs[`marker-${vessel._id}`];
            if (markerRef && markerRef[0] && markerRef[0].$el) {
              const leafletMarker = markerRef[0].$el._leaflet_id
                ? markerRef[0].$el
                : markerRef[0].mapObject;
              if (leafletMarker) {
                leafletMarker.openPopup();
              }
            }
          });
        }
      }
    },

    obtenerRendezvous(startTime, endTime) {
      const requestBody = { startTime, endTime };

      return axios
        .post(getRendezvousInline, requestBody)
        .then((response) => {
          this.allVessels = response.data.events
            .flatMap((event) =>
              (event.vessels || []).map((vessel) => {
                const latitude = vessel.details?.latitude;
                const longitude = vessel.details?.longitude;

                return {
                  eventId: event.event_id,
                  eventType: event.event_type,
                  startTime: event.start?.time,
                  endTime: event.end?.time,
                  name: vessel.name,
                  country: vessel.display_country,
                  mmsi: vessel.mmsi,
                  details: vessel.details,
                  point: {
                    lat: latitude,
                    lon: longitude,
                  },
                  hasValidCoordinates: latitude != null && longitude != null
                };
              })
            );

          this.isVesselsReady = true;
          this.vessels = [...this.allVessels];

          this.vessels.forEach(vessel => {
            if (!vessel.point.lat || !vessel.point.lon) {
              return;
            }
          });

          this.tableData = this.vessels.map((vessel) => ({
            boatName: vessel.name,
            eventType: vessel.eventType,
            timestamp: vessel.details?.time,
            vesselId: vessel.details?.mmsi,
          }));

          const vesselsWithCoords = this.vessels.filter(vessel =>
            vessel.point.lat != null && vessel.point.lon != null
          );

          if (vesselsWithCoords.length > 0) {
            this.center = [vesselsWithCoords[0].point.lat, vesselsWithCoords[0].point.lon];
          }
        })
        .catch(() => {
          this.showAlert = true;
        });
    },

    async consultarZona() {
      if (!this.zonaSeleccionada || !this.fechaInicio || !this.fechaFin) {
        Swal.fire({
          title: 'Error',
          text: 'Por favor, completa todos los campos.',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
        return;
      }

      const requestBody = {
        startTime: new Date(this.fechaInicio).toISOString(),
        endTime: new Date(this.fechaFin).toISOString(),
        aoiId: this.zonaSeleccionada,
      };

      try {
        const response = await axios.post(eventsQueryByArea, requestBody);

        this.allVessels = response.data.events
          .flatMap((event) =>
            (event.vessels || []).map((vessel) => {
              const latitude = vessel.details?.latitude;
              const longitude = vessel.details?.longitude;

              return {
                eventId: event.event_id,
                eventType: event.event_type,
                startTime: event.start?.time,
                endTime: event.end?.time,
                name: vessel.name,
                country: vessel.display_country,
                mmsi: vessel.mmsi,
                details: vessel.details,
                point: {
                  lat: latitude,
                  lon: longitude,
                },
                hasValidCoordinates: latitude != null && longitude != null,
              };
            })
          );

        this.isVesselsReady = true;
        this.vessels = [...this.allVessels];

        // Filtrar y preparar los datos para la tabla
        this.tableData = this.vessels.map((vessel) => ({
          boatName: vessel.name,
          eventType: vessel.eventType,
          timestamp: vessel.details?.time,
          vesselId: vessel.details?.mmsi,
        }));

        // Ajustar el centro del mapa si hay coordenadas válidas
        const vesselsWithCoords = this.vessels.filter(
          (vessel) => vessel.point.lat != null && vessel.point.lon != null
        );

        if (vesselsWithCoords.length > 0) {
          this.center = [vesselsWithCoords[0].point.lat, vesselsWithCoords[0].point.lon];
        }

        Swal.fire({
          title: 'Consulta Exitosa',
          text: 'Los datos han sido obtenidos exitosamente.',
          icon: 'success',
          confirmButtonText: 'Aceptar',
        });
      } catch (error) {

        Swal.fire({
          title: 'Error',
          text: 'Ocurrió un problema al realizar la consulta.',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
      }
    },

    consultarTracking(msi) {
      axios
        .post(skylightEvents, {
          mmsi: msi,
        })
        .then((response) => {

          this.message = response.data.message || null;
          const vesselData = response.data.data.vesselLkp || response.data.data;
          const eventsData = response.data.data.events.items || response.data.data.events || [];

          if (vesselData && vesselData.point) {
            const vessel = {
              _id: vesselData.vessel_id,
              point: vesselData.point,
              events: eventsData,
            };

            this.vessels = [vessel];
            this.center = [vessel.point.lat, vessel.point.lon];
            this.showAlert = true;
            setTimeout(() => {
              this.showAlert = false;
            }, 3000);
          } else {
            this.showAlert = false;
            setTimeout(() => {
              this.showAlert = false;
            }, 3000);
          }
        })
        .catch(() => {
          this.showAlert = true;
        });
    },


    async mostrarEventos(event) {
      this.Loading = true;

      try {

        this.selectedVessel = event;
        this.showEventsButton = true;

        this.decorators = [];
        this.routes = [];


        const response = await axios.post(vesselTrackingAndHistory, { eventId: event.eventId });

        if (response.data?.data?.length > 0) {
          response.data.data.forEach((data) => {
            if (data.geometry?.coordinates && data.geometry.coordinates.length > 0) {
              const coordinates = data.geometry.coordinates.map((coord) => [
                coord[1],
                coord[0],
              ]);

              const routeLayer = L.polyline(coordinates, {
                color: "blue",
                weight: 3,
                opacity: 0.7,
              }).addTo(this.map);
              this.routes.push(routeLayer);

              const decorator = L.polylineDecorator(routeLayer, {
                patterns: [
                  {
                    offset: "50%",
                    repeat: 0,
                    symbol: L.Symbol.arrowHead({
                      pixelSize: 10,
                      polygon: true,
                      pathOptions: { color: "black", fillOpacity: 0.8, weight: 1 },
                    }),
                  },
                ],
              });
              decorator.addTo(this.map);
              this.decorators.push(decorator);
            }
          });
        }

        if (this.routes.length > 0) {
          const allBounds = this.routes.map((route) => route.getBounds());
          const unifiedBounds = allBounds.reduce((acc, bounds) => acc.extend(bounds), L.latLngBounds());
          this.map.fitBounds(unifiedBounds);
        }
      } catch (error) {
        this.showAlert = true;
      } finally {
        this.Loading = false;
      }
    },

    searchBoats() {
      const searchLower = this.searchBoat ? this.searchBoat.toLowerCase() : '';
      const tipoEventoArray = Array.isArray(this.tipoEvento) ? this.tipoEvento : [];

      this.vessels = this.allVessels
        .filter((vessel) => {
          const matchesSearch =
            vessel.name?.toLowerCase().includes(searchLower) ||
            String(vessel.mmsi).includes(searchLower);

          const matchesEvent = tipoEventoArray.length === 0 ||
            tipoEventoArray.includes(vessel.eventType);

          return matchesSearch && matchesEvent;
        });

      if (this.vessels.length > 0) {
        this.center = [this.vessels[0].details.latitude, this.vessels[0].details.longitude];
      }
    },

    updateSuggestions() {
      const searchLower = this.searchBoat ? this.searchBoat.toLowerCase() : '';

      this.suggestions = this.allVessels.filter((vessel) => {
        return (
          vessel.name?.toLowerCase().includes(searchLower) ||
          String(vessel.mmsi).includes(searchLower)
        );
      });

      this.suggestionsMenu = this.searchBoat.length > 0;
    },

    finalizeSearch() {
      const searchLower = this.searchBoat ? this.searchBoat.toLowerCase() : '';
      const tipoEventoArray = Array.isArray(this.tipoEvento) ? this.tipoEvento : [];
      const isMmsi = !isNaN(searchLower) && searchLower.trim() !== '';

      if (isMmsi) {
        this.consultarTracking(searchLower);
      } else {
        this.vessels = this.allVessels.filter((vessel) => {
          const matchesSearch =
            vessel.name?.toLowerCase().includes(searchLower) ||
            String(vessel.mmsi).includes(searchLower);

          const matchesEvent =
            tipoEventoArray.length === 0 ||
            tipoEventoArray.includes(vessel.eventType);

          return matchesSearch && matchesEvent;
        });

        if (this.vessels.length > 0) {
          this.center = [this.vessels[0].details.latitude, this.vessels[0].details.longitude];
        }
      }

      if (this.searchBoat.trim() && !this.searchHistory.includes(this.searchBoat)) {
        this.searchHistory.unshift(this.searchBoat);
        if (this.searchHistory.length > 8) {
          this.searchHistory.pop();
        }
      }

      this.suggestionsMenu = false;
    },
    resetSearchIfEmpty() {
      if (!this.searchBoat.trim()) {
        this.vessels = [...this.allVessels];
        this.suggestions = [];
        this.suggestionsMenu = false;

        this.center = this.defaultCenter || [0, 0];
      }
    },

    selectSuggestion(suggestion) {
      this.searchBoat = suggestion.name;
      this.suggestionsMenu = false;
      this.finalizeSearch();
    },

    applyHistory(history) {
      this.searchBoat = history;
      this.finalizeSearch();
    },

    removeHistory(index) {
      this.searchHistory.splice(index, 1);
    },

    clearHistory() {
      this.searchHistory = [];
    },

    formatDate(date) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      return new Date(date).toLocaleDateString("es-ES", options);
    },

    clearSelection() {
      this.endDate = null;
      this.startDate = null;
      this.tituloBoton = "Últimos Eventos";

      const now = new Date();
      const twoDaysAgo = new Date(now);
      twoDaysAgo.setDate(now.getDate() - 2);
      this.limpiarDecoraciones();

      const startTime = twoDaysAgo.toISOString().split("T")[0] + "T00:00:00";
      const endTime = now.toISOString().split("T")[0] + "T23:59:59";

      this.obtenerRendezvous(startTime, endTime);

      this.showTable = false;
      if (this.routes.length > 0) {
        this.routes.forEach(route => this.map.removeLayer(route));
        this.routes = [];
      }

      if (this.decorators.length > 0) {
        this.decorators.forEach(decorator => this.map.removeLayer(decorator));
        this.decorators = [];
      }
    },
    aplicarFiltro() {
      this.tituloBoton = this.opcionesFiltro[this.selectedFilter];

      if (this.tituloBoton === "Rango Personalizado") {
        return;
      }

      const filtros = {
        "Últimas 6 Horas": 6,
        "Últimas 12 Horas": 12,
        "Últimas 24 Horas": 24,
        "Últimas 48 Horas": 48,
        "Últimas 72 Horas": 72,
        "Última Semana": 7 * 24,
        "Últimas 2 Semanas": 14 * 24,
        "Último Mes": 30 * 24,
      };

      const horas = filtros[this.tituloBoton];
      if (horas) {
        const { startTime, endTime } = this.calcularFechasPorHoras(horas);
        this.obtenerRendezvous(startTime, endTime);
      }
    },

    calcularFechasPorHoras(horas) {
      const now = new Date();
      const startTime = new Date(now.getTime() - horas * 60 * 60 * 1000);
      const endTime = now;

      return {
        startTime: startTime.toISOString().split("T")[0] + "T00:00:00",
        endTime: endTime.toISOString().split("T")[0] + "T23:59:59",
      };
    },

    aplicarFiltroRangoPersonalizado() {
      if (!this.customDateRange || this.customDateRange.length < 2) {
        return;
      }

      const [start, end] = this.customDateRange;
      if (!start || !end) {
        return;
      }
      const startTime = `${start}T00:00:00`;
      const endTime = `${end}T23:59:59`;


      this.tituloBoton = "Rango Personalizado";
      this.menu = false;
      this.obtenerRendezvous(startTime, endTime);
    },



    traducirTipoEvento(eventType) {
      const evento = this.itemsTipoEvento.find((item) => item.value === eventType);
      return evento ? evento.text : "Tipo de evento desconocido";
    },
    getIconName(eventType) {
      const evento = this.itemsTipoEvento.find((item) => item.value === eventType);
      return evento ? evento.icon : "mdi-help-circle";
    },
    limpiarDecoraciones() {
      if (this.decorators.length > 0) {
        this.decorators.forEach(decorator => {
          this.map.removeLayer(decorator);
        });
        this.decorators = [];
      }
    },

    redirectToVessel(vesselId) {
      const baseUrl = "https://sc-production.skylight.earth/vesseldetails";

      const endTime = new Date().toISOString();
      const startTime = new Date(Date.now() - 48 * 60 * 60 * 1000).toISOString();

      const url = `${baseUrl}/${vesselId}?startTime=${encodeURIComponent(
        startTime
      )}&endTime=${encodeURIComponent(endTime)}`;

      window.open(url, "_blank");
    },

    getIconUrl(eventType) {
      const iconMap = {
        'dark_rendezvous': this.dark,
        'fishing_activity_history': this.history,
        'viirs': this.imag,
        'detection': this.detection,
        'standard_rendezvous':  this.standard,
        'speed_range': this.speed,
        default: this.defaul,
      };
      return iconMap[eventType] || iconMap.default;
    },

  },
  mounted() {
    if (this.$refs.mapLeaflet && this.$refs.mapLeaflet.mapObject) {
      this.map = this.$refs.mapLeaflet.mapObject;
    } else {
      return;
    }
    this.searchBoats();
  },
  watch: {
    '$route.query.boat_name': {
      immediate: true,
      handler() {
        if (this.isVesselsReady) {
          this.centrarMapaEnBarco();
        } else {
          this.$watch(
            () => this.isVesselsReady,
            (ready) => {
              if (ready) {
                this.centrarMapaEnBarco();
              }
            }
          );
        }
      },
    },
  }


};
</script>

<style scoped>

.map-card {
  background-color: #fffdfd;
  border-radius: 8px;
}


.custom-alert {
  background-color: #3b82f6;
  color: white;
  display: flex;
  align-items: center;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  width: 50%;
}

.custom-alert .v-icon {
  animation: popIn 0.5s ease-out;
}

@keyframes popIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.buton{
  background:#1AAB8A;
  color:#fff;
  border:none;
  position:relative;
  height:40px;
  font-size:1.6em;
  padding:0 2em;
  cursor:pointer;
  transition:800ms ease all;
  outline:none;
}
.buton:hover{
  background:#fff;
  color:#1AAB8A;
}
.buton:before,buton:after{
  content:'';
  position:absolute;
  top:0;
  right:0;
  height:2px;
  width:0;
  background: #1AAB8A;
  transition:400ms ease all;
}
.buton:after{
  right:inherit;
  top:inherit;
  left:0;
  bottom:0;
}
.buton:hover:before,buton:hover:after{
  width:100%;
  transition:800ms ease all;
}

/*stilos de la tabla*/
.hoverable-row:hover {
  background-color: #f0f4ff !important;
  cursor: pointer;
}

.v-data-table td {
  padding: 12px !important;
}

.v-data-table thead th {
  font-weight: bold;
  text-transform: uppercase;
  background-color: #f5f5f5;
  color: #424242;
  padding: 10px;
}


.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}


.spinner-box {
  width: 300px;
  height: 150px;
  background-color: #1A237E;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}


.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #ccc;
  border-top: 4px solid #0345a8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


</style>

