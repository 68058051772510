import { render, staticRenderFns } from "./ComponentTrackFishing.vue?vue&type=template&id=519c696c&scoped=true"
import script from "./ComponentTrackFishing.vue?vue&type=script&lang=js"
export * from "./ComponentTrackFishing.vue?vue&type=script&lang=js"
import style0 from "./ComponentTrackFishing.vue?vue&type=style&index=0&id=519c696c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "519c696c",
  null
  
)

export default component.exports